import React, { useState } from 'react';
import { Box, Card, Text } from 'rebass';
import { Link } from '../components/Link';
import { withTranslation, useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';

import { ModalProvider } from 'styled-react-modal';

import dayjs from 'dayjs';
import 'dayjs/locale/el';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Hide } from '../components/hide';
import { Hero } from '../components/Hero';
import { Button } from '../components/Button';
import { Container } from '../components/container';
import { EasySteps } from '../components/EasySteps';
import {
  ForCustomersBullets,
  ForCustomersBulletsMobile,
} from './why_weboxit/for_customers_shared';
import { swiperParams } from '../theme';

import HeroBackgroundImageXL from '../images/hero-image-1@2x.jpg';
import ForCustomersYellowTriangle from '../icons/for-customers-yellow-triangle.svg';
import ForCustomersBackground from '../images/for-customers@2x.jpg';
import ForBusinessesBlueTriangle from '../icons/for-businesses-blue-triangle.svg';
import ForBusinessesBackground from '../images/for-businesses@2x.jpg';

import i18n from '../i18n';

/* FOR BUSINESSES SECTION - BEGIN  */

const ForBusinesses = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Mobile Only */}
      <Hide tablet desktop>
        <Box mt={3} px={3} py={4} bg="blues.peacock">
          <Container>
            <Text
              pt={3}
              pb={3}
              color="maize"
              fontSize={'26px'}
              fontWeight="bold"
              lineHeight="1.27"
            >
              {t('why_we_boxit.for_businesses.home.title.for_businesses')}
              <br />
              {t('why_we_boxit.for_businesses.home.title.e_shops')}
            </Text>
            <Text
              pb={4}
              color="white"
              fontSize={['15px']}
              letterSpacing="normal"
              lineHeight="1.6"
              textAlign="left"
            >
              {t('why_we_boxit.for_businesses.home.subtitle')}
            </Text>
            <Box
              mx="auto"
              width={1}
              pt={1}
              pb={3}
              css={{ alignItems: 'center' }}
            >
              <Link to="/why_weboxit/for_eshops_and_businesses">
                <Button
                  fontSize="12px"
                  width="166px"
                  css={{
                    margin: 'auto',
                    display: 'block',
                    borderColor: 'white',
                  }}
                >
                  {t('why_we_boxit.for_businesses.home.button')}
                </Button>
              </Link>
            </Box>
          </Container>
        </Box>
      </Hide>
      {/* Tablet & Desktop */}
      <Hide mobile>
        <Card
          mt={[3, 2, 0]}
          backgroundImage={'url(' + ForBusinessesBackground + ')'}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          css={{ maxWidth: '1440px' }}
          mx="auto"
        >
          <Card
            ml={[0, '51%', '53%']}
            width={[2 / 5, 0.49, 0.47]}
            pr={['20px', '6.5%', '6%', '10%']}
            backgroundImage={'url(' + ForBusinessesBlueTriangle + ')'}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="left center"
          >
            <Box pl={[3, '44px', '138px']} py={[0, 0, 5]}>
              <Text
                pt={['19px', '43px', '75px']}
                pb={[3, 4, 4]}
                pl={[3, 3, 4]}
                color="white"
                fontSize={['34px', '26px', '40px']}
                fontWeight="bold"
                lineHeight="1.27"
                textAlign="right"
              >
                {t('why_we_boxit.for_businesses.home.title.for_businesses')}
                <br />
                {t('why_we_boxit.for_businesses.home.title.e_shops')}
              </Text>
              <Text
                pt={[0, 2, 2]}
                pb={[4, 4, 4]}
                color="white"
                fontSize={['16px', '20px', '24px']}
                fontWeight="normal"
                letterSpacing="normal"
                lineHeight="1.6"
                textAlign="right"
              >
                {t('why_we_boxit.for_businesses.home.subtitle')}
              </Text>
              <Text
                pt={[0, 0, 0]}
                pb={[4, 3, 3]}
                color="white"
                fontSize={['14px', '14px', '18px']}
                fontWeight="normal"
                letterSpacing="normal"
                lineHeight="1.6"
                textAlign="right"
              >
                {t('why_we_boxit.for_businesses.home.content')}
              </Text>
              <Box pb={[3, 5, 5]} css={{ textAlign: 'right' }}>
                <Link to="/why_weboxit/for_eshops_and_businesses">
                  <Button
                    fontSize="12px"
                    px="20px"
                    css={{ borderColor: 'white' }}
                  >
                    {t('why_we_boxit.for_businesses.home.button')}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Card>
        </Card>
      </Hide>
    </>
  );
}; /* FOR BUSINESSES SECTION - END */

/* FOR CUSTOMERS SECTION - BEGIN */
const ForCustomers = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Mobile Only */}
      <Hide tablet desktop>
        <Box mt={3} px={3} py={4} bg="maize">
          <Container>
            <Text
              pt={4}
              pb={3}
              color="blues.medium"
              fontSize={'26px'}
              fontWeight="bold"
              lineHeight="1.27"
            >
              {t('why_we_boxit.for_customers.home.title')}
            </Text>
            <Text
              pb={4}
              color="blues.peacock"
              fontSize={['15px']}
              letterSpacing="normal"
              lineHeight="1.6"
              textAlign="left"
            >
              {t('why_we_boxit.for_customers.home.content_mob')}
            </Text>
            <Box mx="auto" width={1} css={{ alignItems: 'center' }}>
              <Link to="/why_weboxit/for_customers">
                <Button
                  fontSize="12px"
                  width="166px"
                  css={{ margin: 'auto', display: 'block' }}
                >
                  {t('why_we_boxit.for_customers.home.button')}
                </Button>
              </Link>
            </Box>
          </Container>
        </Box>
        <ForCustomersBulletsMobile />
      </Hide>

      {/* Tablet & Desktop */}
      <Hide mobile>
        <Card
          mt={[3, 6, 7]}
          backgroundImage={'url(' + ForCustomersBackground + ')'}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          css={{ maxWidth: '1440px' }}
          mx="auto"
        >
          <Card
            width={[2 / 5, 0.42, 0.42]}
            pl={['20px', '6.5%', '6%', '10%']}
            backgroundImage={'url(' + ForCustomersYellowTriangle + ')'}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="right center"
          >
            <Box pr={[3, '44px', '88px', '110px']} py={[0, 0, 5]}>
              <Text
                pt={[3, 6, '80px']}
                pb={[3, 3, 4]}
                color="blues.medium"
                fontSize={['34px', '26px', '40px']}
                fontWeight="bold"
                lineHeight="1.27"
              >
                {t('why_we_boxit.for_customers.home.title')}
              </Text>
              <Text
                pt={[0, 2, 0]}
                pb={[4, 3, 4]}
                width={0.95}
                color="blues.peacock"
                fontSize={['16px', '22px', '29px']}
                letterSpacing="normal"
                lineHeight="1.14"
                textAlign="left"
              >
                {t('why_we_boxit.for_customers.home.subtitle')}
              </Text>
              <Text
                pt={[0, 2, 0]}
                pb={[4, 3, 4]}
                width={0.95}
                color="blues.peacock"
                fontSize={['14px', '15px', '18px']}
                letterSpacing="normal"
                lineHeight="1.41"
                textAlign="left"
              >
                {t('why_we_boxit.for_customers.home.content')}
                <br />
                <br />
                {t('why_we_boxit.for_customers.home.summary')}
              </Text>
              <Box pb={[3, 6, '80px']}>
                <Link to="/why_weboxit/for_customers">
                  <Button fontSize="12px" px="20px">
                    {t('why_we_boxit.for_customers.home.button')}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Card>
        </Card>
        {/* ForCustomers Content */}
        <Container>
          <ForCustomersBullets />
        </Container>
      </Hide>
    </>
  );
}; /* FOR CUSTOMERS SECTION - END */

const Content = () => {
  const { t } = useTranslation();

  return (
    <>
      <Hero
        title={t('hero.title')}
        subtitle={t('hero.subtitle')}
        phrase={t('hero.phrase')}
        actions={[
          {
            name: t('hero.how_it_works'),
            link: '/why_weboxit/4_steps_tutorial',
          },
          // {
          //   name: t('hero.using_the_station'),
          //   link: '/why_weboxit/smartest_box_delivery',
          // },
        ]}
        showCards="true"
        showHorizontalLine="true"
        bgImg={HeroBackgroundImageXL}
      />
      <EasySteps params={{ swiperParams }} />
      <ForCustomers />
      <ForBusinesses />
    </>
  );
};

const CatchNetlifyRecoveryNullComponent = ({ location }) => {
  const { isLoggedIn } = useIdentityContext();

  const recoverMyAccount = async t => {
    try {
      //const acc = await recoverAccount(t, true)
      navigate(`/password_recovery/`, {
        replace: true,
        state: { token: t },
      });
    } catch (err) {
      console.log('errro Recovering MyAccount', err);
    }
  };

  // important to check for the current pathname here because else you land
  // in a infinite loop
  console.log('CatchNetlifyRecoveryNullComponent');
  console.log('isLoggedIn', isLoggedIn);
  console.log('location', location);
  if (!isLoggedIn && location.hash) {
    const hash = location.hash.split('=');
    if (hash[0] === '#recovery_token') {
      recoverMyAccount(hash[1]);
    }
  }

  return null;
};

const HomePage = ({ ...props }) => {
  return (
    <ModalProvider>
      <CatchNetlifyRecoveryNullComponent {...props} />
      <Layout transparent={true} {...props}>
        <SEO title="Home" keywords={['weboxit', 'boxit']} />
        <Content />
      </Layout>
    </ModalProvider>
  );
};

export default withTranslation()(HomePage);
